.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('./bg.png');
  background-size: cover; /* Cover the entire viewport */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
  font-family: 'Arial', sans-serif; /* or any other sans-serif font */
}

h1{
  font-size: 80px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: 600;
  margin-bottom: 50px;
}

.card {
  display: flex;
  flex-direction: column;
  background: rgba(51, 51, 51, 0.8); /* Semi-transparent dark card background */
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  color: white;
  width: 400px; /* Adjust width as needed */
  backdrop-filter: blur(10px); /* For the frosted glass effect */
  overflow: hidden; /* Ensures nothing spills out of the container */
}

.action-button, .copy-button {
  background: #8C30F5; /* Purple button color */
  border: none;
  border-radius: 15px;
  padding: 12px 20px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  display: block;
  transition: background-color 0.3s ease;
}

.action-button:hover, .copy-button:hover {
  background: #7A28CA; /* Darken the button on hover */
}

.address-display {
 
  height: auto; /* Adjust height to fit content */
  min-height: 150px; /* Minimum height */
  background: #252525; /* Dark input background */
  border: none; /* No border */
  border-radius: 10px;
  padding: 15px;
  color: #DDD; /* Light grey text */
  margin: 10px 0;
  resize: none; /* Prevent resizing */
  line-height: 1.5; /* Better line spacing for multiple lines */
  overflow: auto; /* Add scroll for overflow */
}

.copy-confirm {
  color: #4BB543; /* Success color */
  font-size: 16px;
  margin-top: 5px;
  display: block;
  text-align: center; /* Center the confirmation message */
}
